<template>
  <vx-card>
    <form-wizard
        color="rgba(var(--vs-primary), 1)"
        :title="title"
        :subtitle="subTitle"
        :hide-buttons="true"
    >
      <div class="vx-row">
        <vs-divider class="vx-col w-full mt-5" position="left" color="dark">
          Información de notificación de {{ module }}
        </vs-divider>

        <div class="vx-col md:w-1/2 w-full">
          <label for="recipient" class="w-full select-large">Destinatario</label>
          <vs-input
              id="recipient"
              name="recipient"
              v-model="notificationSettingsModel.recipient"
              v-validate="'required|email'"
              class="w-full"
              autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("recipient") }}</span>
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Sección de filtros módulo</vs-divider>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-row">
            <div class="vx-col md:w-2/2 w-full mt-2">
              <label for="recipient" class="w-full select-large">Categorías</label>
              <v-select
                  multiple
                  v-model="notificationSettingsModel.notifications_rules.entry.category_id"
                  :options="categories"
                  :reduce="item => item.id"
                  label="name"
                  @input="getEntries()"
              />
            </div>
            <div class="vx-col md:w-2/2 w-full mt-5">
              <label for="recipient" class="w-full select-large">Departamentos</label>
              <v-select
                  multiple
                  v-model="notificationSettingsModel.notifications_rules.entry.state_id"
                  :options="states"
                  :reduce="item => item.id"
                  label="name"
                  @input="getCities(notificationSettingsModel.notifications_rules.entry.state_id); getEntries()"
              />
            </div>
            <div class="vx-col md:w-2/2 w-full mt-5">
              <label for="recipient" class="w-full select-large">Ciudades</label>
              <v-select
                  multiple
                  v-model="notificationSettingsModel.notifications_rules.entry.city_id"
                  :options="cities"
                  :reduce="item => item.id"
                  label="name_code"
                  @input="getEntries()"
              />
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-row">
            <div class="vx-col md:w-2/2 w-full mt-2">
              <label for="recipient" class="w-full select-large">Activos</label>
              <v-select
                  multiple
                  v-model="notificationSettingsModel.notifications_rules.entry.id"
                  :options="entries"
                  :reduce="item => item.id"
                  label="name"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <div class="flex flex-wrap justify-between">
            <vs-row vs-type="flex" vs-justify="flex-end">
              <vs-button color="danger" type="border" class="mb-2" @click="goToBack">Cancelar</vs-button>
              <vs-button class="ml-3 mb-2" @click="createOrUpdateNotificationSettings" :disabled="!validateForm">
                Guardar
              </vs-button>
            </vs-row>
          </div>
        </div>
      </div>
    </form-wizard>
  </vx-card>
</template>

<script>
import CoreClient from "../../utils/coreClient";
import EntriesClient from "../../utils/entriesClient";
import AttributeOptionsClient from "../../utils/attributeOptionsClient";
import NotificationSettingsClient from "../../utils/notificationSettingsClient";
import Notifier from "./../../utils/notification";
import {FormWizard, TabContent} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import "flatpickr/dist/flatpickr.css";
import vSelect from "vue-select";
import {Validator} from "vee-validate";


let notifier = new Notifier();
const NOTIFICATION_SETTINGS_CLIENT = new NotificationSettingsClient()
const STATES_CLIENT = new CoreClient("states");
const CITIES_CLIENT = new EntriesClient("cities");
const ENTRIES_CLIENT = new EntriesClient("assets");
const ATTRIBUTE_OPTIONS_CLIENT = new AttributeOptionsClient("assets");

const requiredFields = ["recipient"];

let dict = requiredFields.reduce(
    (previousValue, currentValue) => {
      previousValue[currentValue] = {
        required: "* Este campo es requerido",
        email: "* Correo electrónico inválido"
      };
      return previousValue
    },
    {}
);

Validator.localize("es", {custom: dict});


export default {
  data() {
    return {
      title: "",
      subTitle: "",
      module: "",
      entry_type: "",
      cities: [],
      states: [],
      categories: [],
      entries: [],
      queryStrings: {
        category_id: [],
        state_id: [],
        city_id: [],
      },
      notificationSettingsModel: {
        recipient: "",
        notifications_rules: {
          entry: {
            category_id: null,
            state_id: null,
            city_id: null,
            id: null,
          },
          form_data: {},
        },
      },
    };
  },

  mounted() {
    if (this.$route.params.id !== undefined) {
      this.$vs.loading();
      this.getPageTitle(true);
      this.loadNotificationSettingData(this.$route.params.id).then(() => {
        this.$vs.loading.close();
      });
    } else {
      this.getPageTitle(false);
      this.getStates(false)
      this.getCategories(false)
          .then(() => {
            this.getEntries()
                .then(() => {
                  this.$vs.loading.close();
                })
          });
    }
  },

  computed: {
    validateForm() {
      return !this.errors.any()
          && requiredFields.reduce((accumulator, currentValue) => {
            return accumulator && this.notificationSettingsModel[currentValue] !== null;
          });
    }
  },

  methods: {
    getPageTitle(update) {
      this.title = update ? `Editar destinatario de Activos Eventuales` : `Crear destinatario de Activos Eventuales`
      this.subTitle = "Configurar destinatario y los criterios para recibir notificaciones."
      this.module = "Activos Eventuales"
      this.entry_type = "assets"
    },

    async getEntries(showLoading = true) {
      if (showLoading) this.$vs.loading();

      let category = this.notificationSettingsModel.notifications_rules.entry.category_id
      let state = this.notificationSettingsModel.notifications_rules.entry.state_id
      let city = this.notificationSettingsModel.notifications_rules.entry.city_id

      this.queryStrings.category_id = Array.isArray(category) ? category : []
      this.queryStrings.state_id = Array.isArray(state) ? state : []
      this.queryStrings.city_id = Array.isArray(city) ? city : []

      await ENTRIES_CLIENT.all({queryStrings: this.queryStrings})
          .then(response => {
            this.entries = response.data;
            this.cleanArrayEntries(
                this.notificationSettingsModel.notifications_rules.entry.id,
                response.data,
                this.queryStrings
            )

            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
    },

    async getStates(showLoading = true) {
      if (showLoading) this.$vs.loading();

      await STATES_CLIENT.all()
          .then(response => {
            if (showLoading) this.$vs.loading.close();
            this.states = response.data;
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
    },

    async getCities(state, showLoading = true) {
      if (Array.isArray(state) && state.length > 0) {
        if (showLoading) this.$vs.loading();

        await CITIES_CLIENT.all({queryStrings: {state_id: state}})
            .then(response => {
              response.data.forEach(el => el.name_code = `(${el.state_id}) ${el.name}`)
              response.data.sort((a, b) => (a.state_id > b.state_id) ? 1 : ((b.state_id > a.state_id) ? -1 : 0))

              let cities = response.data.map(el => el.id)

              this.notificationSettingsModel.notifications_rules.entry.city_id = this.cleanArray(
                  this.notificationSettingsModel.notifications_rules.entry.city_id, cities
              )
              this.cities = response.data;

              if (showLoading) this.$vs.loading.close();
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });
      } else {
        this.cities = [];
        this.notificationSettingsModel.notifications_rules.entry.city_id = null;
      }
    },

    async getCategories(showLoading = true) {
      if (showLoading) this.$vs.loading();

      await ATTRIBUTE_OPTIONS_CLIENT.categories({queryStrings: {representation_type: "full"}})
          .then(response => {
            if (showLoading) this.$vs.loading.close();
            this.categories = response.data;
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
    },

    async loadNotificationSettingData(id) {
      await NOTIFICATION_SETTINGS_CLIENT.retrieve(this.entry_type, id)
          .then(response => {
            this.getCategories(false)
            this.getStates(false)
                .then(() => {
                  this.getCities(response.data.notifications_rules.entry.state_id, false)
                      .then(() => {
                        this.getEntries()
                            .then(() => {
                              Object.assign(this.notificationSettingsModel, response.data);
                            })
                      })
                })
          })
          .catch(error => {
            notifier.notification("error");
          });
    },

    async createOrUpdateNotificationSettings() {
      let status_validation = true

      await this.$validator.validateAll().then(el => {
        status_validation = el
      })

      if (status_validation) {
        if (this.notificationSettingsModel.id !== null && this.notificationSettingsModel.id !== undefined && this.notificationSettingsModel.id !== "") {
          this.$vs.loading();
          this.cleanModel();

          await NOTIFICATION_SETTINGS_CLIENT.update(
              this.notificationSettingsModel, this.entry_type, this.$route.params.id
          )
              .then(response => {
                this.$vs.loading.close();
                notifier.notification("updated");
                this.goToBack();
              })

              .catch(error => {
                this.errors = error.response.data.errors;
                this.$vs.loading.close();
                notifier.alertMessage("error");
              });
        } else {
          this.$vs.loading();
          this.cleanModel();

          await NOTIFICATION_SETTINGS_CLIENT.create(this.notificationSettingsModel, this.entry_type)
              .then(response => {
                this.$vs.loading.close();
                notifier.notification("created");
                this.goToBack();
              })
              .catch(error => {
                if (error.response.status === 403 && error.response.data.errors.recipient !== undefined) {
                  this.errors.add({
                    field: 'recipient',
                    msg: `Ya existe un registro con el correo ${this.notificationSettingsModel.recipient} para ${this.module}`
                  })
                }

                this.$vs.loading.close();
                notifier.alertMessage("error");
              });
        }
      }
    },

    goToBack() {
      this.$router.push({name: "notification-settings", params: {entry_type: this.entry_type}});
    },

    cleanModel() {
      let exclude = ["entry_type", "id", "unix_created_at", "unix_updated_at"]

      Object.keys(this.notificationSettingsModel).forEach(key => {
        if (exclude.includes(key)) {
          delete this.notificationSettingsModel[key];
        }
      })

      this.notificationSettingsModel.notifications_rules.entry = this.cleanNullKeys(
          this.notificationSettingsModel.notifications_rules.entry
      )
      this.notificationSettingsModel.notifications_rules.form_data = this.cleanNullKeys(
          this.notificationSettingsModel.notifications_rules.form_data
      )
    },

    cleanNullKeys(data) {
      let newObject = {}

      for (let key in data) {
        if (data[key] !== null && data[key].length > 0) {
          newObject[key] = data[key]
        }
      }

      return newObject;
    },

    cleanArray(selected_items, response) {
      if (Array.isArray(selected_items)) {
        return selected_items.filter(el => {
          if (response.includes(el)) return el
        })
      }

      return null
    },

    cleanArrayEntries(selected_items, response, queryString) {
      if (Array.isArray(selected_items)) {
        let array_selected = response.filter(el => {
          if (selected_items.includes(el.id)) return el
        })

        array_selected = array_selected.map(el => {
          if (queryString.category_id.includes(el.category_id)) {
            return el.id
          } else if (queryString.state_id.includes(el.state_id)) {
            return el.id
          } else if (queryString.city_id.includes(el.city_id)) {
            return el.id
          }
        })

        if (array_selected.includes(undefined)) {
          this.notificationSettingsModel.notifications_rules.entry.id = null
          return false
        }
        this.notificationSettingsModel.notifications_rules.entry.id = array_selected
      }
    }
  },

  components: {
    FormWizard,
    TabContent,
    "v-select": vSelect,
  }
};

</script>